/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// @ts-nocheck

import {
  CostInsightsApi,
  ProductInsightsOptions,
  Alert,
  Cost,
  Entity,
  Group,
  Project,
  MetricData,
  Tag,
} from '@internal/plugin-cost-insights-tsystems';
import { getLastCompleteBillingDate, splitIntervals } from './insightsUtils';
import {
  _getGroupDailyCost,
  _getProductInsights,
  _getProjectDailyCostByTags,
} from './aws/awsService';
import { ConfigApi } from '@backstage/core-plugin-api';
import { CloudProviders, AWSCostClient, AzureCostClient } from './cost.model';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { getAWSAlerts, getAWSGroupTags, initializeClientAws } from './aws/awsClient';
import { initializeClientAzure } from './azure/azureClient';
import { _getGroupDailyCostAzure } from './azure/azureService';

export class CostInsightsClient implements CostInsightsApi {
  clients: Array<AWSCostClient | AzureCostClient> = [];
  private configApi: ConfigApi;
  private catalogApi: CatalogApi;

  constructor(configApi: ConfigApi, catalogApi: CatalogApi) {
    this.configApi = configApi;
    this.catalogApi = catalogApi;
    this.initializeClients();
  }

  async fetchCatalogInfo(entityRef: string): Promise<any> {
    const r = await this.catalogApi.getEntityByRef(entityRef);

    if (!r || !r.kind) {
      throw new Error(`Failed to fetch catalog info for ${entityRef}`);
    }
    return r;
  }

  async initializeClients() {
    const accounts = this.configApi.getConfigArray('costInsights.accessKeys');
    for (const account of accounts) {
      console.log('Account in app-config', account)
      const provider = account.get('provider');
      let fullClient: any;
      switch(provider){
        case CloudProviders.AWS:
          fullClient = initializeClientAws(account);
          break;
        case CloudProviders.AZURE:
          fullClient = initializeClientAzure(account);
          break;
        case CloudProviders.GCP:
          break;
        default:
          throw new Error('Cloud Provider not defined in app-config.yaml');
      }
      this.clients.push(fullClient);
    }

    for(const client of this.clients){
      client.tags = [...await this.getGroupTags(client.account)] || []
    }
    // eslint-disable-next-line no-console
    console.log('Clients', this.clients);
  }

  getGroupTags(group: string): Promise<Tag[]>{
    return new Promise(async (resolve)=>{
      const client = this.clients.find(x => x.account === group);
      let tags: Tag[] = [];
      if(client){
        switch(client?.provider){
          case CloudProviders.AWS:
            tags = await getAWSGroupTags(client);
            break;
          case CloudProviders.AZURE:
            break;
          case CloudProviders.GCP:
            break;
          default:
            throw new Error('Cloud Provider not defined in app-config.yaml');
        }
      }else{ throw new Error(`No client available for the account: ${group}`); }
      resolve(tags);
    })
  }

  async getUserGroups(_userId: string): Promise<Group[]> {
    return this.clients.map(client => ({
      id: client.account,
      name: client.name,
    }));
  }

  async getGroupProjects(_group: string): Promise<Project[]> {
    return []
  }

  async getLastCompleteBillingDate(): Promise<string> {
    return getLastCompleteBillingDate();
  }

  async getAlerts(group: string): Promise<Alert[]> {
    return new Promise(async (resolve)=>{
      const client = this.clients.find(x => x.account === group);
      let alerts: Alert[] = [];
      if(client){
        switch(client?.provider){
          case CloudProviders.AWS:
            alerts = await getAWSAlerts(client);
            break;
          case CloudProviders.AZURE:
            break;
          case CloudProviders.GCP:
            break;
          default:
            throw new Error('Cloud Provider not defined in app-config.yaml');
        }
      }else{ throw new Error(`No client available for the account: ${group}`); }
      resolve(alerts);
    })
  }

  async getDailyMetricData(
    _metric: string,
    _intervals: string,
  ): Promise<MetricData> {
    // eslint-disable-next-line no-console
    // console.log('getDailyMetricData', metric, intervals);
    return {
      id: 'remove-me',
      format: 'number',
      aggregation: [
        { date: '2024-03-08', amount: 169.442 },
        { date: '2020-03-09', amount: 169.442 },
        { date: '2020-03-10', amount: 169.442 },
        { date: '2020-03-11', amount: 169.442 },
        { date: '2020-03-12', amount: 169.442 },
        { date: '2020-03-13', amount: 169.442 },
        { date: '2020-03-14', amount: 169.442 },
      ],
      change: {
        ratio: 200,
        amount: 50,
      },
    };
  }

  async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
    // // eslint-disable-next-line no-console
    // console.log('getGroupDailyCost', group, intervals);
    const intervalDates = splitIntervals(intervals);

    const client:AWSCostClient | AzureCostClient | undefined = this.clients.find(x => x.account === group);
    let dailyCost: any;
    if(client){
      try{
        switch(client?.provider){
          case CloudProviders.AWS:
            dailyCost = await _getGroupDailyCost(client.ceClient,intervalDates,group);
            break;
          case CloudProviders.AZURE:
            dailyCost = await _getGroupDailyCostAzure(client, intervalDates, group);
            break;
          case CloudProviders.GCP:
            break;
          default:
            throw new Error('Cloud Provider not defined in app-config.yaml');
        }
        return dailyCost;
      }catch(error){
        throw new Error(`Error fetching data: ${error}`);
      }
    }else{ throw new Error(`No client available for the account: ${group}`); }
  }

  async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
    // eslint-disable-next-line no-console
    // console.log('getProjectDailyCostByTags', tags, intervals);
    const intervalDates = splitIntervals(intervals);
    const urlParams = new URLSearchParams(window.location.search);
    const group = urlParams.get('group');
    if (!group) { throw new Error('Group not found in URL'); }

    const client:AWSCostClient | AzureCostClient | undefined = this.clients.find(x => x.account === group);
    let dailyCost: any;
    if(client){
      try{
        switch(client?.provider){
          case CloudProviders.AWS:
            dailyCost = await _getProjectDailyCostByTags(client.ceClient,intervalDates,[project],group);
            break;
          case CloudProviders.AZURE:
            break;
          case CloudProviders.GCP:
            break;
          default:
            throw new Error('Cloud Provider not defined in app-config.yaml');
        }
        return dailyCost;
      }catch(error){
        throw new Error(`Error fetching data: ${error}`);
      }
    }else{ throw new Error(`No client available for the account: ${group}`); }
  }

  async getProjectDailyCostByTags(tags: Tag[], intervals: string): Promise<Cost> {
    // eslint-disable-next-line no-console
    // console.log('getProjectDailyCostByTags', tags, intervals);
    const intervalDates = splitIntervals(intervals);
    const urlParams = new URLSearchParams(window.location.search);
    const group = urlParams.get('group');
    if (!group) { throw new Error('Group not found in URL'); }

    const client:AWSCostClient | AzureCostClient | undefined = this.clients.find(x => x.account === group);
    let dailyCost: any;
    if(client){
      try{
        switch(client?.provider){
          case CloudProviders.AWS:
            dailyCost = await _getProjectDailyCostByTags(client.ceClient,intervalDates,tags,group);
            break;
          case CloudProviders.AZURE:
            break;
          case CloudProviders.GCP:
            break;
          default:
            throw new Error('Cloud Provider not defined in app-config.yaml');
        }
        return dailyCost;
      }catch(error){
        throw new Error(`Error fetching data: ${error}`);
      }
    }else{ throw new Error(`No client available for the account: ${group}`); }
  }

  async getCatalogEntityDailyCost(catalogEntityRef: string,intervals: string,): Promise<Cost> {
    const intervalDates = splitIntervals(intervals);

    const project = catalogEntityRef.split('/')[1];
    if (!project || project === '') throw new Error(`Project not found: ${project}`);

    const catalogData = await this.fetchCatalogInfo(catalogEntityRef);
    const provider: string = catalogData.metadata.cloudCosts?.keys();
    if(provider.length>1) throw new Error(`Multiple cloud providers not allowed for project: ${project}`);
    if(provider.length === 0) throw new Error(`Cloud Provider not found for project: ${project}`);

    const projectTags: Tag[] = catalogData.metadata.cloudCosts?.[provider[0]].tags ?? [];
    if (!projectTags || projectTags.length === 0) { throw new Error(`Tags not found in catalog for project: ${project}`);}

    const projectCloud: string = catalogData.metadata.cloudCosts?.[provider[0]].account;
    if(!projectCloud){throw new Error(`Cloud account not found in catalog for project: ${project}`);}

    const client:AWSCostClient | AzureCostClient | undefined = this.clients.find(x => x.account === projectCloud);
    let dailyCost: any;
    if(client){
      try{
        switch(client?.provider){
          case CloudProviders.AWS:
            dailyCost = await _getProjectDailyCostByTags(client.ceClient,intervalDates,projectTags,projectCloud);
            break;
          case CloudProviders.AZURE:
            break;
          case CloudProviders.GCP:
            break;
          default:
            throw new Error('Cloud Provider not defined in app-config.yaml');
        }
        return dailyCost;
      }catch(error){
        throw new Error(`Error fetching data: ${error}`);
      }
    }else{ throw new Error(`No client available for the account: ${projectCloud}`); }
  }

  async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {
    const intervalDates: any = splitIntervals(options.intervals);
    const project = options.project || options.group;

    let provider: any;
    let projectTags: Tag[] = [];
    const cloudCosts: any = options.entity?.metadata.cloudCosts;
    if(cloudCosts){ 
      provider = cloudCosts.keys(); 
      if(provider.length>1) throw new Error(`Multiple cloud providers not allowed for project: ${project}`);
      if(provider.length === 0) throw new Error(`Cloud Provider not found for project: ${project}`);

      projectTags = cloudCosts[provider[0]].tags ?? [];

      const projectCloud: string = cloudCosts[provider[0]].account;
      if(!projectCloud){throw new Error(`Cloud account not found in catalog for project: ${project}`);}

      options.group = projectCloud
    }

    const client:AWSCostClient | AzureCostClient | undefined = this.clients.find(x => x.account === options.group);
    let dailyCost: any;
    if(client){
      try{
        switch(client?.provider){
          case CloudProviders.AWS:
            dailyCost = await _getProductInsights(client.ceClient,intervalDates,options,projectTags);
            break;
          case CloudProviders.AZURE:
            break;
          case CloudProviders.GCP:
            break;
          default:
            throw new Error('Cloud Provider not defined in app-config.yaml');
        }
        return dailyCost;
      }catch(error){
        throw new Error(`Error fetching data: ${error}`);
      }
    }else{ throw new Error(`No client available for the account: ${options.group}`); }
  }

}
