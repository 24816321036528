import { AzureCostClient } from "../cost.model";
import { ClientSecretCredential } from '@azure/identity';
import { CostManagementClient } from '@azure/arm-costmanagement';

export function initializeClientAzure(account: any): AzureCostClient{

  const tenantId = account.getString('tenant');
  const clientId = account.getString('app');
  const clientSecret = account.getString('secret');


  const credential = new ClientSecretCredential(tenantId, clientId, clientSecret);
  const client = new CostManagementClient(credential);

  const fullClient: AzureCostClient = {
    account: account.getString('account'),
    provider: account.get('provider'),
    tags: [],
    name: account.get('name'),
    ceClient: client,
  }
  console.log('FullClient', fullClient);
  return fullClient;
}