import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  identityApiRef,
  oauthRequestApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { costInsightsApiRef } from '@internal/plugin-cost-insights-tsystems';
import { CostInsightsClient } from './components/costInsights/CostInsightsClient';
import { OAuth2 } from '@backstage/core-app-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { visitsApiRef, VisitsStorageApi } from '@backstage/plugin-home';

export const htpcOIDCAuthApiRef: ApiRef<OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi> = createApiRef({
  id: 'htpc-auth-provider',
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),
  createApiFactory({
    api: costInsightsApiRef,
    deps: {configApi: configApiRef, discoveryApi: discoveryApiRef, catalogApi: catalogApiRef},
    factory: ({ configApi, catalogApi }) => {
      return new CostInsightsClient(configApi, catalogApi);
    },
  }),
  createApiFactory({
    api: htpcOIDCAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'htpc-auth-provider',
          title: 'HTPC auth provider',
          icon: () => null,
        },
        environment: configApi.getOptionalString('auth.environment'),
        defaultScopes: ['openid', 'profile', 'email'],
        popupOptions: {
          // optional, used to customize login in popup size
          size: {
            fullscreen: false,
          },
          /**
           * or specify popup width and height
           * size: {
              width: 1000,
              height: 1000,
            }
           */
        },
      }),
  }),
  ScmAuth.createDefaultApiFactory(),
];

