import { AWSCostClient as CostClient } from "../cost.model";
import {Alert, AlertStatus, Tag} from '@internal/plugin-cost-insights-tsystems';
import { CostExplorerClient, GetTagsCommand } from '@aws-sdk/client-cost-explorer';
import { ResourceGroupsTaggingAPIClient, GetTagValuesCommand } from "@aws-sdk/client-resource-groups-tagging-api";
import { getLastCompleteBillingDate } from "../insightsUtils";

export function initializeClientAws(catalogAccount: any): CostClient{
  const ceClient = new CostExplorerClient({
    region: 'eu-west-1',
    credentials: {
      accessKeyId: catalogAccount.get('accessKeyId'),
      secretAccessKey: catalogAccount.get('accessKeySecret'),
    },
  });
  const tagClient = new ResourceGroupsTaggingAPIClient({
    region: 'eu-west-1',
    credentials: {
      accessKeyId: catalogAccount.get('accessKeyId'),
      secretAccessKey: catalogAccount.get('accessKeySecret'),
    },
  });
  const fullClient: CostClient = {
      account: catalogAccount.get('account'),
      provider: catalogAccount.get('provider'),
      tags: [],
      name: catalogAccount.get('name'),
      ceClient: ceClient,
      tagClient: tagClient,
  }
  return fullClient;
}

export function getAWSGroupTags(client: CostClient): Promise<Tag[]>{
    return new Promise(async (resolve)=>{
        const tags: Tag[] = [];
        const result = await client.ceClient.send(new GetTagsCommand({
          TimePeriod: {
            Start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0], // format YYYY-MM-DD
            End: await getLastCompleteBillingDate()
          },
          Filter:{
            Dimensions: { Key: 'LINKED_ACCOUNT', Values: [client.account] },
          }
        }));
        if(result?.Tags && result.Tags.length > 0){
          for (const tag of result.Tags) {
            const result2 = await client.tagClient.send(new GetTagValuesCommand({Key: tag}));
            if (result2?.TagValues && result2.TagValues.length > 0) {
              tags.push({ key: tag, values: result2.TagValues });
            } else { throw new Error(`No TagValues available for the account: ${client.account}`)}
          }
        }else{ throw new Error(`No Tags available for the account: ${client.account}`); }
      resolve(tags);
    });
}

export function getAWSAlerts(_client: CostClient): Promise<Alert[]>{
    return new Promise((resolve)=>{
        const exampleAlert: Alert[] = [
            {
            title: 'S3 cost 20% higher this month.',
            subtitle:
                'Based on our projection, a 20% increase in S3 billing is expected.',
            status: AlertStatus.Snoozed,
            },
        ];
        resolve(exampleAlert);
    });
}