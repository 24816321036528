import { QueryDefinition, QueryTimePeriod } from '@azure/arm-costmanagement';
import { Cost } from '@internal/plugin-cost-insights-tsystems';
import { AzureCostClient } from '../cost.model';

// TODO: Implement resource tags filter
export function _getGroupDailyCostAzure(
  completeClient: AzureCostClient,
  intervalDates: { startDate: Date; endDate: Date },
  _group: string,
): Promise<Cost> {
  return new Promise(async (resolve, reject) => {

    const timePeriod: QueryTimePeriod = {
      from: intervalDates.startDate,
      to: intervalDates.endDate,
    };
  
    const query: QueryDefinition = {
      type: 'Usage',
      timeframe: 'Custom',
      timePeriod,
      dataset: {
        granularity: 'Daily',
        aggregation: {
          totalCost: {
            name: 'PreTaxCost',
            function: 'Sum',
          },
        },
        // filter: {
        //   dimensions: {
        //     name: 'ResourceGroupName',
        //     operator: 'In',
        //     values: [group],
        //   },
        // },
      },
    };

    try {
      const subscriptionId = completeClient.account;
      console.log('error is here')
      const scope = `/subscriptions/${subscriptionId}`;
      const data = await completeClient.ceClient.query.usage(scope, query);
      console.log('_getGroupDailyCost', data);


      const dailyCost: Cost = {
        id: 'daily-cost',
        trendline: { slope: 0, intercept:3 }, // slope inclinació, intercept is the horizontal value (the average)
        aggregation: [],
      };
      resolve(dailyCost);
    } catch (error) {
      reject(error);
    }
  });
}