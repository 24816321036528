import { createTranslationResource } from '@backstage/core-plugin-api/alpha';
import { userSettingsTranslationRef } from '@backstage/plugin-user-settings/alpha';

export const userSettingsMessages = createTranslationResource({
  ref: userSettingsTranslationRef,
  translations: {
    es: () =>
      Promise.resolve({
        default: {
          'languageToggle.title': 'Idioma',
          'languageToggle.select': 'Idioma {{language}}',
          'languageToggle.description': 'Cambia el idioma',
          'themeToggle.title': 'Tema',
          'themeToggle.description': 'Cambia el tema',
          'themeToggle.select': 'Tema {{theme}}',
          'themeToggle.selectAuto': 'Auto',
          'themeToggle.names.auto': 'Auto',
          'themeToggle.names.dark': 'Oscuro',
          'themeToggle.names.light': 'Claro',
        },
      }),
  },
});

// export const coreComponentsMessages = createTranslationResource({
//   ref:coreComponentsTranslationRef,
//   translations: {
//     es: () =>
//       Promise.resolve({
//         default: {
//           "alertDisplay.message_one": "Este es un mensaje de alerta",
//           "alertDisplay.message_other":"",
//           "autoLogout.stillTherePrompt.buttonText":"",
//           "autoLogout.stillTherePrompt.title":"",
//           "copyTextButton.tooltipText":"",
//           "emptyState.missingAnnotation.actionTitle":"",
//           "emptyState.missingAnnotation.readMore":"",
//           "emptyState.missingAnnotation.title":"",
//           "table.filter.title": "",
//           "table.filter.clearAll": "",
//           "signIn.loginFailed": "",
//           "errorBoundary.title": "",
//           "errorPage.goBack": "",
//           "errorPage.showLessDetails": "",
//           "errorPage.showMoreDetails": "",
//           "errorPage.subtitle": "",
//           "errorPage.title": "",
//           "oauthRequestDialog.authRedirectTitle": "",
//           "oauthRequestDialog.login": "",
//           "oauthRequestDialog.rejectAll": "",
//           "oauthRequestDialog.title": "",
//           "proxiedSignInPage.title": "",
//           "signIn.customProvider.continue": "",
//           "signIn.customProvider.idToken": "",
//           "signIn.customProvider.subtitle": "",
//           "signIn.customProvider.title": "",
//           "signIn.customProvider.tokenInvalid": "",
//           "signIn.customProvider.userId": "",
//           "signIn.guestProvider.enter": "",
//           "signIn.guestProvider.subtitle": "",
//           "signIn.guestProvider.title": "",
//           "signIn.loginFailed": "",
//           "signIn.title": "",
//           "simpleStepper.back": "",
//           "simpleStepper.finish": "",
//           "simpleStepper.next": "",
//           "simpleStepper.reset": "",
//           "simpleStepper.skip": "",
//           "supportButton.close": "",
//           "supportButton.title": "",
//           "supportConfig.default.linkTitle": "",
//           "supportConfig.default.title": ""
//         },
//       }),
//   },
// });


// export const scaffolderMessages = createTranslationResource({
//     ref: scaffolderTranslationRef,
//     translations: {
//       es: () =>
//         Promise.resolve({
//           default: {
//             'scaffolder.title': 'Plantillas',
//             'scaffolder.description': 'Plantillas de desarrollo',

//           },
//         }),
//     },
// });

